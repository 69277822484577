.product
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 16px
  border: 1px solid $border
  border-radius: 4px

  &_removable
    .title_h2
      max-width: calc(100% - 40px)

  img
    width: 56px
    height: 56px
    border-radius: 4px

  &__status
    position: absolute
    top: 0
    right: 0
    padding: 4px 8px
    background: $main
    border-radius: 0 4px 0 8px
    color: $invert

    &_promo
      background: $gradient-spectrum
