@mixin field
  width: 100%
  height: 32px
  padding: 0 8px
  border: 1px solid $border
  border-radius: 2px
  color: $text-main
  font-size: .875rem
  line-height: 1rem
  transition: border-color $transition-options
  outline: none

  &:focus
    border-color: $main

  &_active
    border-color: $main

  &_error
    border-color: $accent

  &_min
    width: 132px

  &_max
    width: 100%

  &::placeholder
    color: $text-optional

@mixin dark-field
  background-color: #67777e
  border-color: #939B9f
  color: $invert

  &:focus
    border-color: $main

  &_active
    border-color: $main

  &_error
    border-color: $accent

  &:not(&_active):not(&_error)
    border-color: #939B9f

    &:focus
      border-color: $main

  &::placeholder
    color: #d9d9d9

@mixin scrollbar
  overflow: auto
  scrollbar-width: thin
  scrollbar-color: $main rgba(91, 198, 234, .15)

  @supports (overflow: overlay)
    overflow: overlay

  &::-webkit
    &-scrollbar
      width: 2px
      height: 2px

      &-thumb
        background-color: $main
        border-radius: 1px

@mixin scrollbar-wide
  overflow: auto
  scrollbar-color: $main rgba(91, 198, 234, .15)

  @supports (overflow: overlay)
    overflow: overlay

  &::-webkit
    &-scrollbar
      width: 7px
      height: 7px

      &-thumb
        background-color: $main
        border-radius: 3.5px
