.section
  background: $invert
  border-radius: 4px
  box-shadow: 0 0 1px rgba(0, 45, 77, .3), 0 3px 4px rgba(0, 45, 77, .05)

  &__head
    display: flex
    justify-content: space-between
    align-items: center
    gap: 12px
    padding: 12px
    cursor: pointer
    user-select: none

    @media (hover)
      &:hover
        .icon
          fill: $accent

    &_nav
      padding: 12px 12px 0

      .btn
        margin-top: -6px

  &__body
    padding: 16px
    border-top: 1px solid $border
