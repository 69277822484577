.link
  display: inline-block
  color: $main
  font-size: .875rem
  line-height: 1rem
  transition: color $transition-options

  .text &
    font-size: inherit
    line-height: inherit

  &:not(&_disabled)
    cursor: pointer

    @media (hover)
      &:hover
        color: $accent

        .icon
          fill: $accent

  &_s
    font-size: .75rem

  &_bold
    font-weight: 500

  &_dark
    color: $text-main

  &_light
    color: $text-optional

  &_orange
    color: $accent

    @media (hover)
      &:hover
        color: $text-main

  &_underlined
    text-decoration: underline

  &_disabled
    opacity: .5

span.link
  user-select: none
