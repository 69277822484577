.code
  display: inline-flex
  gap: 4px
  padding: 8px 40px
  background: #d3fdc4
  border-radius: 12px
  font-size: 1.25rem
  font-weight: 600
  line-height: 1.5rem

  .dark-theme &
    color: $text-main
