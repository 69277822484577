.checker
  @media #{$mobile}
    flex-grow: 1

  &__userfields
    display: flex
    flex-grow: 1

    .table__container
      width: 100%

    .table__cell
      &:first-of-type
        padding-left: 24px

      &:last-of-type
        padding-right: 24px
