.input
  position: relative
  width: 272px

  @media #{$mobile}
    width: 100%
    max-width: 272px

  &_min
    max-width: 132px

  &_max
    width: 100%

  &_collapsible
    transition: min-width $transition-options, max-width $transition-options

  &_collapsed
    max-width: 32px

    & > .input__field_with-icon
      padding-right: 22px

    & > .btn
      @media (hover)
        &:hover
          & > .icon
            fill: $main

      & > .icon
        fill: $border

  @media #{$mobile}
    min-width: 132px
    max-width: 100%

  &__field
    transition: opacity $transition-options

    &:disabled
      pointer-events: none
      opacity: .6

    &_with-icon
      padding-right: 32px

      & ~ .btn, & ~ .icon
        position: absolute
        top: 0
        right: 0
