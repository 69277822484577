.popup
  width: 100%
  display: grid
  justify-items: center
  align-items: center

  &_bg
    position: fixed
    top: 0
    left: 0
    height: 100%
    padding: 16px
    background: $overlay
    z-index: 1
    +scrollbar-wide

  &__wrapper
    width: 100%
    max-width: 370px
    padding: 16px
    background: $invert
    border-radius: 4px
    box-shadow: $shadow

    &_width

      &-600
        max-width: 600px

      &-644
        max-width: 644px

      &-790
        max-width: 790px
        padding: 24px

      &-1080
        max-width: 1080px
        padding: 24px

    & > .title_h2
      &:first-of-type
        padding-right: 32px
