.header
  position: sticky
  top: 0
  display: grid
  grid-auto-flow: column
  justify-content: space-between
  align-items: center
  grid-column-gap: 40px
  min-height: 64px
  background: $invert
  padding: 16px 40px
  box-shadow: 0 4px 17px rgba(181, 192, 203, .33)
  z-index: 1

  @media #{$mobile}
    padding: 16px

  &__logo
    width: 108px
    height: 16px
    background: url('#{$path}/img/logo.svg') no-repeat center / contain

    @media #{$mobile}
      width: 26px
      background-image: url('#{$path}/img/logo-min.svg')

  &__actions
    display: grid
    grid-auto-flow: column
    grid-column-gap: 12px
