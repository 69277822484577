.list
  font-size: .875rem
  padding-left: 16px

  &__item
    &:not(:last-of-type)
      margin-bottom: 4px

    .link
      display: inline
