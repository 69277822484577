.switch
  display: flex
  padding: 12px 24px
  background: $invert
  border: 1px solid $border
  border-radius: 8px
  transition: background $transition-options, box-shadow $transition-options

  @media (hover)
    &:hover
      background: $bg
      box-shadow: 0 1px 1px rgba(0, 45, 77, .1), 0 2px 6px rgba(0, 45, 77, .05)

  &:not(&_active)
    cursor: pointer

  &_active
    background: $bg
    box-shadow: 0 1px 1px rgba(0, 45, 77, .1), 0 2px 6px rgba(0, 45, 77, .05)
