.connection-list

  &__filter
    flex: 1 0

    &.wrapper_align-items-flex-end
      @media (max-width: 813px)
        align-items: flex-start
        order: -1
        flex-basis: 100%
