.title
  font-weight: 500
  transition: visibility $transition-options, opacity $transition-options

  &_h1
    font-size: 1.375rem
    line-height: 1.625rem

  &_h2
    font-size: 1.125rem
    line-height: 1.375rem

  &_h3
    font-size: .875rem
    line-height: 1rem

  &_white
    color: $invert

  &_gray
    color: $text-optional

  &_green
    color: $optional

  &_hidden
    visibility: hidden
    opacity: 0
    transition: none

  .link
    font-size: inherit
