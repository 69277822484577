.universal-btn
  padding: 0
  background: transparent
  cursor: pointer

  .title_bg-red
    transition: background $transition-options

  @media (hover)
    &:hover
      .title_bg-red
        background: #ff5934

        .dark-theme &
          background: #ff5934
