.keyword
  position: relative

  &__keyword
    position: absolute
    top: 0
    left: 0
    display: inline-block
    justify-content: space-between
    width: 100%
    color: transparent
    pointer-events: none
