.toggle
  display: flex
  width: fit-content
  max-width: 272px
  transition: opacity $transition-options
  user-select: none

  &_disabled
    opacity: .6

  &:not(&_disabled)
    cursor: pointer

  &__slider
    position: relative
    width: 20px
    height: 14px
    flex-shrink: 0
    background: $border
    border-radius: 16px
    transition: background $transition-options, left $transition-options

    &:after
      content: ''
      position: absolute
      top: 2px
      left: 2px
      width: 10px
      height: 10px
      background: $invert
      box-shadow: $shadow
      border-radius: 50%
      transition: left $transition-options, background $transition-options

  input
    display: none

    &:checked
      & ~ .toggle__slider
        background: $main

        &::after
          left: 8px
