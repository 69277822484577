.background
  flex-grow: 1
  width: max-content
  max-width: 100%
  padding: 16px
  background: $bg
  border-radius: 4px

  .dark-theme &
    background: #646b6d
