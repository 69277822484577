.method
  display: flex
  flex-direction: column
  align-items: center
  padding: 24px 24px 32px
  background: $invert
  border: 1px solid $border
  border-radius: 4px
  box-shadow: 0 0 8px rgba(0, 45, 77, .05), 0 8px 32px rgba(0, 45, 77, .15)
  text-align: center
  transition: border-color $transition-options, box-shadow $transition-options

  @mixin active
    border-color: $optional
    box-shadow: 0 0 8px rgba(0, 45, 77, .05), 0 8px 8px rgba(0, 45, 77, .15)

  &:not(&_selected)
    cursor: pointer

    @media (hover)
      &:hover
        +active

  &_selected
    +active

  &_disabled
    opacity: .5
    pointer-events: none
