.dark-theme
  .section
    background: #586367
    box-shadow: none

    &__head

      @media (hover)
        &:hover
          .icon
            fill: $main

    &__body
      background: linear-gradient(180deg, #505759 0%, #364b52 100%)
      border-top: none
      border-radius: 0 0 4px 4px
