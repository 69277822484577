$laptop: '(max-width: 1024px)'
$tablet: '(max-width: 768px)'
$mobile: '(max-width: 425px)'

$path: '../../public/static/'

$main: #5bc6ea
$accent: #ff8242
$bg: #f3fafc
$border: #d4e6ed
$text-main: #4c4c4c
$text-optional: #939b9f
$text-light: #d9d9d9
$invert: #fff
$optional: #88c734
$tangerine: #ff998b

$gradient-spectrum: linear-gradient(270deg, $main 0%, #ff9547 69.06%, $accent 100%)

$overlay: rgba(0, 34, 72, .5)

$shadow: 0 0 8px rgba(0, 45, 77, .05), 0 32px 40px rgba(0, 45, 77, .15)

$transition-options: .2s ease-in-out

@keyframes fadeIn
  0%
    opacity: 0

  100%
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1

  100%
    opacity: 0
