
.advice
  position: relative
  width: max-content
  max-width: 100%
  padding: 16px
  background: $bg
  border-radius: 4px

  &_small
    padding: 12px

    & > .advice__icon
      top: 10px
      left: 10px

  &_clickable
    transition: background $transition-options
    cursor: pointer
    user-select: none

    &.advice_type

      &-info
        @media (hover)
          &:hover
            background: darken($bg, 2)

            .dark-theme &
              background: darken(#586367, 2)

      &-warning
        @media (hover)
          &:hover
            background: darken(#fff5f0, 2)

            .dark-theme &
              background: darken(#586367, 2)

  &_type
    @mixin type
      padding-left: 44px

      &.advice_small
        padding-left: 40px

      & > .advice__icon
        background-repeat: no-repeat
        background-position: center
        background-size: contain

    &-info
      +type

      & > .advice__icon
        background-image: url('#{$path}/img/notice/info.svg')

      & > .title_h3
        color: $main

    &-star
      +type

      & > .advice__icon
        background-image: url('#{$path}/img/notice/star.svg')

      & > .title_h3
        color: $main

    &-success
      +type
      background: rgb(136, 199, 52, .1)

      & > .advice__icon
        background-image: url('#{$path}/img/notice/success.svg')

      & > .title_h3
        color: $optional

    &-warning
      +type
      background: #fff5f0

      & > .advice__icon
        background-image: url('#{$path}/img/notice/warning.svg')

      & > .title_h3
        color: $accent

  &_white
    background: $invert
    box-shadow: 0 0 1px rgba(0, 45, 77, .3), 0 3px 4px rgba(0, 45, 77, .05)

  &__icon
    position: absolute
    top: 13px
    left: 12px
    width: 20px
    height: 20px
