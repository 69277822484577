.select
  width: 100%
  max-width: 272px

  @media #{$mobile}
    max-width: 100%

  &_min
    max-width: 132px

  &_max
    max-width: 100%

  &__field
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0px 32px 0px 8px
    background: url('#{$path}/img/icons/angle-down.svg') no-repeat 100% 0%
    transition: background-image $transition-options, border-color $transition-options
    cursor: pointer
    user-select: none

    &_disabled
      opacity: .6
      cursor: default

    &:not(&_disabled)
      @media (hover)
        &:hover
          background-image: url('#{$path}/img/icons/angle-down_orange.svg')

    &_min
      padding-left: 0
      background-color: transparent !important
      border: none

  &__placeholder
    color: $text-optional

  &__preview
    &:not(&_min)
      margin-left: -4px
      padding: 4px 8px
      background: $main
      border-radius: 1px
      color: $invert

    &_bold
      font-weight: 500

    &_flex
      display: flex
      gap: 3.5px

  &__container
    position: absolute

  &__dropdown
    position: absolute
    top: 8px
    left: -8px
    width: 100%
    max-height: 288px
    padding: 8px 0
    background: $invert
    border-radius: 4px
    box-shadow: $shadow
    z-index: 1
    cursor: default
    +scrollbar

    &_full-width
      width: auto

    &_multi
      padding: 16px 0

      &.select__dropdown_with-search
        padding: 0 0 16px

        .select__dropdown-search
          margin-bottom: 16px

    &_with-search
      padding: 0 0 8px

    &-search
      position: sticky
      top: 0
      margin-bottom: 8px
      padding: 8px
      background: $invert

  &__options
    display: flex
    flex-direction: column

    &-group
      padding: 8px 16px

      &_first
        padding-top: 0

    &-item
      padding: 8px 24px
      transition: background $transition-options
      white-space: initial

      &_selected
        background: $bg

      &:not(&_selected)
        cursor: pointer

        @media (hover)
          &:hover
            background: $bg

    &-inner
      padding: 0 24px
